<template>
  <div class="mainContent">
    <v-row justify="center" align="center" class="linksActivitiesSection" no-gutters>
      <div v-bind:class="mobile ? 'titleSectionMobile' : 'titleSection'" v-html="$t('title')"></div>
    </v-row>
    <v-row justify="space-between" class="content" v-if="!mobile" no-gutters>
      <v-img
          v-for="(partner, index) in sectionsFirstLine"
          :key="index"
          :src="partner.image"
          :alt="partner.title"
          height="100%"
          max-width="150"
      />
    </v-row>
    <br v-if="!mobile">
    <br v-if="!mobile">
    <v-row justify="space-between" class="content" v-if="!mobile" no-gutters>
      <v-img
          v-for="(partner, index) in sectionsSecondLine"
          :key="index"
          :src="partner.image"
          :alt="partner.title"
          height="100%"
          max-width="150"
      />
    </v-row>
    <v-row justify="space-around" class="contentMobile" v-if="mobile" v-bind:no-gutters="mobile">
      <v-img
          v-for="(partner, index) in partners"
          :key="index"
          :src="partner.image"
          :alt="partner.title"
          height="100%"
          max-width="100"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'HomePartner',
  props: ['mobile'],
  data: () => ({
    partners: [
      {
        image: require('@/assets/partners/cnsa.png'),
        title: 'CNSA',
      },
      {
        image: require('@/assets/partners/caissedepargne.png'),
        title: "Caisse d'épargne",
      },
      {
        image: require('@/assets/partners/departement06.png'),
        title: 'Département Alpes Maritimes',
      },
      {
        image: require('@/assets/partners/assuranceMaladie.png'),
        title: 'Assurance maladie',
      },
      {
        image: require('@/assets/partners/mutualiteFrancaise.png'),
        title: 'Mutualité Française',
      },
      {
        image: require('@/assets/partners/argirEtArco.png'),
        title: 'agrirc et arrco',
      },
      {
        image: require('@/assets/partners/msa.png'),
        title: 'MSA',
      },
      {
        image: require('@/assets/partners/associationPACA.png'),
        title: 'Association santé PACA',
      },
      {
        image: require('@/assets/partners/carsat.png'),
        title: "Carsat",
      },
      {
        image: require('@/assets/partners/ars.png'),
        title: 'ARS',
      },
      {
        image: require('@/assets/partners/lesPep.png'),
        title: 'Les pep',
      },
      {
        image: require('@/assets/partners/djscs.png'),
        title: 'Direction régionale de la jeunesse des sports et de la cohésion sociale',
      },
      {
        image: require('@/assets/partners/isatis.png'),
        title: 'isatis',
      },
      {
        image: require('@/assets/partners/asDuCoeur.png'),
        title: 'AS du coeur',
      }
    ],
    sectionsFirstLine: [
      {
        image: require('@/assets/partners/cnsa.png'),
        title: 'CNSA',
      },
      {
        image: require('@/assets/partners/caissedepargne.png'),
        title: "Caisse d'épargne",
      },
      {
        image: require('@/assets/partners/departement06.png'),
        title: 'Département Alpes Maritimes',
      },
      {
        image: require('@/assets/partners/assuranceMaladie.png'),
        title: 'Assurance maladie',
      },
      {
        image: require('@/assets/partners/mutualiteFrancaise.png'),
        title: 'Mutualité Française',
      },
      {
        image: require('@/assets/partners/argirEtArco.png'),
        title: 'agrirc et arrco',
      },
      {
        image: require('@/assets/partners/msa.png'),
        title: 'MSA',
      }
    ],
    sectionsSecondLine: [
      {
        image: require('@/assets/partners/associationPACA.png'),
        title: 'Association santé PACA',
      },
      {
        image: require('@/assets/partners/carsat.png'),
        title: "Carsat",
      },
      {
        image: require('@/assets/partners/ars.png'),
        title: 'ARS',
      },
      {
        image: require('@/assets/partners/lesPep.png'),
        title: 'Les pep',
      },
      {
        image: require('@/assets/partners/djscs.png'),
        title: 'Direction régionale de la jeunesse des sports et de la cohésion sociale',
      },
      {
        image: require('@/assets/partners/isatis.png'),
        title: 'isatis',
      },
      {
        image: require('@/assets/partners/asDuCoeur.png'),
        title: 'AS du coeur',
      }
    ]
  })
}
</script>

<i18n>
{
  "en": {
    "title": "Partners"
  },
  "fr": {
    "title": "Partenaires"
  }
}
</i18n>

<style scoped>

.mainContent {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: inset 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.titleSection {
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.titleSectionMobile {
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

</style>