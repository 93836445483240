<template>
  <div v-bind:class="mobile ? 'mainContentMobile' : 'mainContent'">
    <v-row justify="center" align="center" v-bind:class="mobile ? 'contentMobile' : 'content'" no-gutters>
      <v-col v-bind:cols="mobile ? 12 : 6">
        <v-img
            :src="require('@/assets/main/activite.jpg')"
            contain
            height="350"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 12 : 6" style="text-align: center">
        <div v-bind:class="mobile ? 'textSectionMobile' : 'textSection'" v-html="$t('text')"></div>
        <br>
        <br v-if="!mobile">
        <v-btn v-bind:class="mobile ? 'calculateBtnMobile' : 'calculateBtn'"
               rounded
               v-bind:width="mobile || width < 1900  ? '300px' : '450px'"
               height="65px"
               dark
               v-html="$t('textBtn')"
               href="https://www.mangerbouger.fr/bouger-plus/test-de-niveau-d-activite-physique-et-de-sedentarite"
               color="#57939E">
        </v-btn>
      </v-col>
    </v-row>
    <v-img
        v-if="!mobile"
        position="95% bottom"
        style="margin-top: -80px"
        :src="require('@/assets/picto/picto10.svg')"
        contain
        height="120"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeCalculate',
  props: ['mobile'],
  data: () => ({
    width: screen.width
  }),
  methods: {
  },
}
</script>

<i18n>
{
  "en": {
    "text": "<strong> Do you want to practice a physical activity in complete safety adapted to your state of form? </strong> <br> <br> <strong> Vitaé Sport Santé supports you </strong> to find the activity that suits you best",
    "textBtn": "Calculate my level <br> of physical activity"
  },
  "fr": {
    "text": "<strong>Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? </strong> <br><br> <strong>Vitaé Sport Santé vous accompagne </strong> afin de trouver l’activité qui vous conviendra le mieux",
    "textBtn": "Calculer mon niveau <br> d’activité physique"
  }
}
</i18n>

<style scoped>

.mainContent {
  background-color: #D5EEF4;
  padding-top: 55px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: inset 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.mainContentMobile {
  background-color: #D5EEF4;
  padding-bottom: 40px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: inset 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

@media screen and (min-width: 1500px){
  .calculateBtn {
    padding-right: 50px;
    padding-left: 50px;
    font-weight: 200;
    font-size: 18px;
    text-transform: none;
  }
}

@media screen and (max-width: 1500px){
  .calculateBtn {
    padding-right: 30px;
    padding-left: 30px;
    font-weight: 200;
    font-size: 16px;
    text-transform: none;
  }
}

.calculateBtnMobile {
  padding-right: 50px;
  padding-left: 50px;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
}

.textSection {
  font-size: 17px;
  padding-right: 80px;
  padding-left: 80px;
  text-align: center;
  color: #57939E;
}

.textSectionMobile {
  font-size: 14px;
  text-align: center;
  color: #57939E;
}

</style>
