<template>
  <div class="mainContent">
    <v-row
        justify="center"
        align="center"
        v-bind:class="mobile ? 'linksActivitiesSectionMobile' : 'linksActivitiesSection'"
        no-gutters>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col
          v-for="(link, index) in linksActivities1"
          :key="index"
          v-bind:style="mobile ? 'height: 220px' : 'height: 285px'"
          v-bind:cols="mobile ? 6 : 2">
        <v-img
            class="imgActivities"
            :src="link.image"
            contain
            @click="routing(link.name)"
            v-bind:height="mobile ? 125 : 175"
        />
        <h3 v-bind:class="mobile ? 'titleActivityMobile' : 'titleActivity'"
            @click="routing(link.name)"
            v-html="$t(link.title)"></h3>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row
        justify="center"
        align="center"
        v-bind:class="mobile ? 'linksActivitiesSectionMobile1' : 'linksActivitiesSection1'"
        no-gutters>
      <v-col cols="3" v-if="!mobile"></v-col>
      <v-col
          v-for="(link, index) in linksActivities2"
          :key="index"
          v-bind:style="mobile ? 'height: 220px' : 'height: 285px'"
          v-bind:cols="mobile ? 6 : 2">
        <v-img
            class="imgActivities"
            :src="link.image"
            contain
            @click="routing(link.name)"
            v-bind:height="mobile ? 125 : 175"
        />
        <h3 v-bind:class="mobile ? 'titleActivityMobile' : 'titleActivity'"
            @click="routing(link.name)"
            v-html="$t(link.title)"></h3>
      </v-col>
      <v-col cols="3" v-if="!mobile"></v-col>
    </v-row>
    <v-img
        position="10% bottom"
        v-bind:max-width="mobile ? '75px' : '200px'"
        style="margin-top: -140px; margin-left: 10%;"
        :src="require('@/assets/picto/picto3.svg')"
        contain
        v-bind:height="mobile ? 120 : 175"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeActivitiesLinks',
  props: ['mobile'],
  data: () => ({
    linksActivities1: [
      {
        name: 'PhysicalActivities',
        title: 'activity1',
        image: require('@/assets/vitaeImg/section25.png'),
      },
      {
        name: 'Ephad',
        title: 'activity2',
        image: require('@/assets/vitaeImg/section26.png'),
      },
      {
        name: 'EnterpriseSport',
        title: 'activity4',
        image: require('@/assets/vitaeImg/section9.png'),
      },
      {
        name: 'activity5',
        title: 'activity5',
        image: require('@/assets/vitaeImg/section32.png'),
      }
    ],
    linksActivities2: [
      {
        name: 'ConferenceSportHealth',
        title: 'activity6',
        image: require('@/assets/vitaeImg/section24.png'),
      },
      {
        name: 'PhysicalActivitiesAway',
        title: 'activity7',
        image: require('@/assets/vitaeImg/section20.png'),
      },
      {
        name: 'activity8',
        title: 'activity8',
        image: require('@/assets/vitaeImg/section27.png'),
      }
    ]
  }),
  methods: {
    routing(nameRoute) {
      this.$vuetify.goTo(0)
      if (this.$route.name !== nameRoute) {
        this.$router.push({name: nameRoute}).then(() => {
          this.$vuetify.goTo(0)
        })
      }
    }
  },
}
</script>

<i18n>
{
  "en": {
    "activity1": "Seniors in shape",
    "activity2": "Adapted physical activity in EHPAD and independent residence",
    "activity3": "Sport insertion",
    "activity4": "Business sport",
    "activity5": "Prescription sports",
    "activity6": "Conferences and training",
    "activity7": "My physical activity adapted from a distance",
    "activity8": "Individual care"
  },
  "fr": {
    "activity1": "Seniors en forme",
    "activity2": "Maintenir la santé de nos aînés",
    "activity3": "Sport insertion",
    "activity4": "Sport entreprise",
    "activity5": "Sport thérapie",
    "activity6": "Conférences et formations",
    "activity7": "Mon activité physique adaptée à distance",
    "activity8": "Prise en charge individuelle"
  }
}
</i18n>

<style scoped>

.mainContent {
  padding-top: 10px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: inset 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.linksActivitiesSection {
  padding-top: 80px;
  padding-bottom: 50px;
}

.linksActivitiesSectionMobile {
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.linksActivitiesSectionMobile1 {
  padding-left: 15px;
  padding-right: 15px;
}

.linksActivitiesSection1 {
  padding-bottom: 20px;
}

.imgActivities {
  cursor: pointer;
}

.titleActivity {
  cursor: pointer;
  padding-top: 30px;
  text-align: center;
  font-weight: normal;
  color: #1579A7;
}

.titleActivityMobile {
  cursor: pointer;
  font-size: 16px;
  padding-top: 15px;
  text-align: center;
  font-weight: normal;
  color: #1579A7;
}

</style>
