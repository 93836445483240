<template>
  <div>
    <Carousel v-bind:mobile="mobile" />
    <HomeCalculate v-bind:mobile="mobile" />
    <HomeNews v-bind:mobile="mobile" />
    <HomeActivitiesLinks v-bind:mobile="mobile" />
    <HomePartner v-bind:mobile="mobile" />
    <FollowUs v-bind:mobile="mobile" />
  </div>
</template>

<script>
  import Carousel from '@/components/desktop/main/Carousel'
  import HomeActivitiesLinks from '@/components/desktop/main/HomeActivitiesLinks'
  import HomeCalculate from '@/components/desktop/main/HomeCalculate'
  import HomeNews from '@/components/desktop/main/HomeNews'
  import HomePartner from '@/components/desktop/main/HomePartner'
  import FollowUs from '@/components/desktop/FollowUs'

  export default {
    name: 'Home',
    props: ['mobile'],
    components: {
      Carousel,
      HomeActivitiesLinks,
      HomeNews,
      HomePartner,
      HomeCalculate,
      FollowUs
    },
  }
</script>
