<template>
  <v-carousel
      class="carousel"
      height="640"
      dark
      hide-delimiter-background
      show-arrows-on-hover
  >
    <v-carousel-item
        v-for="(item,i) in carousel"
        :key="i"
        :src="item.image"
        reverse-transition="fade-transition"
        transition="fade-transition"
    >
      <div v-bind:class="mobile ? 'textCarouselMobile' : 'textCarousel'">
        <h2 class="titleCarousel">{{ item.title }}</h2>
        <br>
        <p> {{ item.description }}</p>
      </div>
      <v-img
          position="-40px bottom"
          class="pictoCarousel"
          :src="require('@/assets/picto/picto9.svg')"
          contain
          height="400"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import axios from "axios";

export default {
  name: 'Carousel',
  props: ['mobile'],
  mounted() {
    axios.get('https://api.vitaesportsante.com/api/carousels', {
      page: 1,
      order: 'asc',
    })
        .then(response => {
          this.$set(this, 'carousel', response.data['hydra:member'])
        })
        .catch(error => console.log(error))
        .finally(() => this.loading = false)
  },
  data: () => ({
    carousel: []
  }),
}
</script>

<style scoped>

.titleCarousel {
  width: 300px;
  font-family: Comfortaa, sans-serif;
}

.textCarousel {
  font-size: 15px;
  width: 330px;
  color: #FFFFFF;
  z-index: 10;
  position: relative;
  top: 450px;
  left: 40px;
}

.textCarouselMobile {
  font-size: 12px;
  width: 330px;
  color: #FFFFFF;
  z-index: 10;
  position: relative;
  top: 500px;
  left: 10px;
}

.pictoCarousel {
  margin-top: 250px;
}

.carousel {
  margin-top: -55px;
}

</style>
