<template>
  <v-row
      justify="center"
      align="center"
      v-bind:class="mobile ? 'contentMobile sectionNewsMobile' : 'content sectionNews'"
      no-gutters>
    <v-col cols="12">
      <div v-bind:class="mobile ? 'titleSectionMobile' : 'titleSection'" v-html="$t('title')"></div>
    </v-col>
    <v-col v-bind:cols="mobile ? 12 : 4" class="news">
      <div v-html="news[0]"></div>
    </v-col>
    <v-col v-bind:cols="mobile ? 12 : 4" class="news">
      <div v-html="news[1]"></div>
    </v-col>
    <v-col v-bind:cols="mobile ? 12 : 4" class="news">
      <div v-html="news[2]"></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HomeNews',
  props: ['mobile'],
  mounted() {
    //axios.get('https://api.vitaesportsante.com/api/news', {
    //  page: 1,
    //  order: 'asc',
    //})
    //.then(response => {this.$set(this, 'news', response.data['hydra:member'])})
    //.catch(error => console.log(error))
    //.finally(() => this.loading = false)

    
    let iframes = document.querySelectorAll("iframe")
    iframes.forEach((iframe) => {
      iframe.width = this.mobile ? 300 : 350
    })
  },
  data: () => ({
    news: [
    '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fvitaesportsante%2Fposts%2Fpfbid0SkdaC69kxMvaPBmyXzqyPhjxBMshqvpt58WHbSEBRCEHt8kVQ7QaNJzwKZKamGrQl&show_text=true&width=500" width="500" height="771" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',
    '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fvitaesportsante%2Fposts%2Fpfbid02JkpFPNXraYEprUkLCpL9k49F2JdtfEBvyZutLBrziezwkPrE2U5NtCmJ7TD8AtgPl&show_text=true&width=500" width="500" height="744" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',
    '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fvitaesportsante%2Fposts%2Fpfbid0zSrbZPrHGuDv8urVS7YwHoSKJ3ut4mNbrLWM3Sfxvyr6gVMkDtKuZAThWoJWL1srl&show_text=true&width=500" width="500" height="498" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
    ]
  }),
  methods: {},
}
</script>

<i18n>
{
  "en": {
    "title": "Our news"
  },
  "fr": {
    "title": "Nos actualités"
  }
}
</i18n>

<style scoped>

.news {
  text-align: center;
  margin-bottom: 20px;
}

.titleSection {
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}
.titleSectionMobile {
  padding-bottom: 35px;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.sectionNews {
  padding-top: 55px;
  padding-bottom: 55px;
}

.sectionNewsMobile {
  padding-top: 35px;
  padding-bottom: 55px;
}

</style>
